import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import PageHeader from '~/components/PageHeader';
import { DownloadPowerpoint } from '~/gatsby-theme-docz/components/DownloadPowerpoint';
import PowerpointImage from './powerpoint.png';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader"></PageHeader>
    <p>{`I Powerpoint-malen kan du endre bilde ved å klikke på ikonet.
Malen velger automatisk utsnitt, og skalerer opp bildet dersom det kreves.
Om utsnittet ikke er slik du ønsker, kan du høyreklikke på bildet og velge "Beskjær".
Dra bildet slik at utsnittet blir etter ditt ønske.`}</p>
    <DownloadPowerpoint mdxType="DownloadPowerpoint"></DownloadPowerpoint>
    <img src={PowerpointImage} width="100%" alt="Illustrerer bruken av Entur sin skjermbakgrunn på en dataskjerm" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      